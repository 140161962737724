import {
  createContext,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';

const platformTheme = (platformName: string) =>
  ({
    ANDROIDPLANET: 'sf-theme-ap',
    IPHONED: 'sf-theme-ip',
    ANDROIDWORLDNL: 'sf-theme-aw',
    ANDROIDWORLDBE: 'sf-theme-aw',
  }[platformName]);

export const StickyHeaderContext = createContext(false);

interface LayoutProps {
  platformName: string;
  Filters: ReactElement;
  Header?: ReactElement;
  Results: ReactElement;
}

const Layout = ({platformName, Filters, Header, Results}: LayoutProps) => {
  const headerRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setIsSticky(!entry.isIntersecting);
      },
      {
        rootMargin: '10px',
        threshold: 1,
      },
    );
    if (headerRef.current) {
      observer.observe(headerRef.current);
    }
  }, []);

  return (
    <div
      className={`${platformTheme(
        platformName,
      )} sf-bg-white sf-mx-auto sf-relative`}
    >
      <StickyHeaderContext.Provider value={isSticky}>
        <div
          ref={headerRef}
          className="sf-h-24 sf-top-8 sf-right-0 sf-w-[1px] sf-absolute -sf-z-10"
        />
        {Header && <>{Header}</>}
      </StickyHeaderContext.Provider>
      <div className="sf-max-w-screen-xl sf-w-full sf-mx-auto sf-grid sf-grid-cols-1 lg:sf-grid-cols-[1fr_2.2fr]">
        <div className="sf-pt-4 sf-w-full sf-px-4 md:sf-px-8 xl:sf-px-0 sf-bg-white sf-pb-2" data-name="filters">
          {Filters}
        </div>
        <div className="sf-px-4 md:sf-px-8 xl:sf-px-0" data-name="offer-results">{Results}</div>
      </div>
    </div>
  );
};

export default Layout;
